import React from 'react';
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import getLocalData from "../functions/getLocalData"
import UserImage from "../functions/getUserImage"


const SideNav = (props) => {
  const auth = useAuth();
  return (
    <div>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboard/main" className="brand-link">
          <img
            src="/dist/img/gestelev-logo-single.png"
            alt="GestElev Logo"
            className="brand-image"
          />
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <UserImage className="img-circle elevation-2 bg-white"/>
            </div>
            <div className="info">
              <a href="#" className="d-block">
                { `${getLocalData("user", "FirstName")} ${getLocalData("user", "LastName")}` }
              </a>
              <button onClick={() => auth.logOut()} className="btn-submit">
                logout
              </button>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link to="/dashboard/main" className={ props.activeItem===1 ? "nav-link active" : "nav-link" }>
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Dashboard
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/customer/list" className={ props.activeItem===2 ? "nav-link active" : "nav-link" }>
                  <i className="nav-icon fas fa-address-book" />
                  <p>
                    Clientes
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/proposal/list" className={ props.activeItem===3 ? "nav-link active" : "nav-link" }>
                  <i className="nav-icon fas fa-handshake" />
                  <p>
                    Propostas
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contract/list" className={ props.activeItem===4 ? "nav-link active" : "nav-link" }>
                  <i className="nav-icon fas fa-id-card" />
                  <p>
                    Contratos
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/budgetproposal/list" className={ props.activeItem===5 ? "nav-link active" : "nav-link" }>
                  <i className="nav-icon fas fa-file" />
                  <p>
                    Orçamentos
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/elevator/list" className={ props.activeItem===6 ? "nav-link active" : "nav-link" }>
                  <i className="nav-icon fas fa-building" />
                  <p>
                    Elevadores
                  </p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}

export default SideNav
