import { React, useState, useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import SideNav from "../../../components/SideNav";
import Footer from "../../../components/Footer";
import DocumentTitle from '../../../functions/setDocumentTitle';
import { fetchAPI } from '../../../functions/fetchAPI';
import SnackbarAlert from '../../../components/SnackbarAlert';
import Loading from '../../../components/Loading';
import { Form } from "react-bootstrap";
import ContactList from '../../../components/contact/list_Contact';
import AddressList from '../../../components/address/list_Address';
import NoteList from '../../../components/note/list_Note';
import ProposalList from '../../../components/proposal/list_Proposal';
import ContractList from '../../../components/contract/list_Contract';
import BudgetProposalList from '../../../components/budgetproposal/list_budgetProposal';
import ElevatorList from '../../../components/elevator/list_Elevator';
import AddressCreate from "../../../components/address/popup_create";
import ContactCreate from "../../../components/contact/popup_create";
import NoteCreate from "../../../components/note/popup_create";
import ProposalCreate from "../../../components/proposal/popup_create";
import getLocalData from "../../../functions/getLocalData";
import ProposalDetail from "../../../components/proposal/popup_details";
import ContractCreate from "../../../components/contract/popup_create";
import ContractDetail from "../../../components/contract/popup_details";
import ElevatorCreate from "../../../components/elevator/popup_create";

const CustomersDetails = () => {

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState({
    isLoading: false,
    message: ""
  });
  /**/

  const [form_Data, setFormData] = useState({
    Customers_Id: '',
    Customers_Reference: '',
    Customers_Name: '',
    Customers_TaxNumber: '',
    Customers_DoorCode: '',
    Customers_ExternalManagement: '',
    Customers_CustomerStatusId: "",
    Customers_PaymentMethodId: "",
    Customers_CustomerManagerId: "",
    Customers_RouteId: "",
    Customers_CustomerComesFromId: "",
  });

  const [statusList, setStatus] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [paymentMethodList, setPaymentMethod] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [customerManagerList, setCustomerManager] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [routesList, setRoutes] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [comesFromList, setComesFrom] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [modalAddressCreate, setModalAddressCreate] = useState(false);
  const [modalContactCreate, setModalContactCreate] = useState(false);
  const [modalNoteCreate, setModalNoteCreate] = useState(false);
  const [modalProposalCreate, setModalProposalCreate] = useState(false);
  const [modalContractCreate, setModalContractCreate] = useState(false);
  const [modalElevatorCreate, setModalElevatorCreate] = useState(false);

  const [modalProposalDetail, setModalProposalDetail] = useState({
    show: false,
    id: 0,
  });

  const [modalContractDetail, setModalContractDetail] = useState({
    show: false,
    id: 0,
  });

  const [refreshComponents, setRefreshComponents] = useState({
    addressList: '',
    contactList: '',
    noteList: '',
    proposalList: '',
    contractList: '',
    elevatorList: '',
  });

  async function getCustomersDetail(id) {
    setLoading({
      isLoading: true,
      message: "A obter dados ..."
    });
    try {
      const fetchData = await fetchAPI(
        `/api.php/customer/detail?id=${id}`,
        "GET"
      );
      const fetchCustomerStatus = await fetchAPI(
        `/api.php/customer/listStatus`,
        "GET"
      );
      const fetchPaymentsMethod = await fetchAPI(
        `/api.php/system/paymentmethod/list?lowInfo=1&isEnabled=1&tenantId=${getLocalData("tenant", "id")}`,
        "GET"
      );
      const fetchCustomerManagers = await fetchAPI(
        `/api.php/system/customermanager/list?lowInfo=1&isEnabled=1&tenantId=${getLocalData("tenant", "id")}`,
        "GET"
      );
      const fetchRoutes = await fetchAPI(
        `/api.php/system/route/list?lowInfo=1&isEnabled=1&tenantId=${getLocalData("tenant", "id")}`,
        "GET"
      );
      const fetchComesFrom = await fetchAPI(
        `/api.php/system/comesfrom/list?lowInfo=1&isEnabled=1&tenantId=${getLocalData("tenant", "id")}`,
        "GET"
      );
      const res = await fetchData;
      const resCustomerStatus = await fetchCustomerStatus;
      const resPaymentsMethod = await fetchPaymentsMethod;
      const resCustomerManagers = await fetchCustomerManagers;
      const resRoutes = await fetchRoutes;
      const resComesFrom = await fetchComesFrom;
      setFormData(res);
      setStatus(resCustomerStatus);
      setPaymentMethod(resPaymentsMethod);
      setCustomerManager(resCustomerManagers);
      setRoutes(resRoutes);
      setComesFrom(resComesFrom);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
    setLoading({
      isLoading: false
    });
  }

  async function updateCustomer() {
    setLoading({
      isLoading: true,
      message: "A atualizar o cliente ..."
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/customer/update",
        "POST",
        {
          Customer: {
            Id: form_Data.Customers_Id,
            Name: form_Data.Customers_Name,
            TaxNumber: form_Data.Customers_TaxNumber,
            CustomerStatusId: form_Data.Customers_CustomerStatusId,
          }
        }
      );
      const res = await fetchData;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
    } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
          type: "error",
        });
    }
    setLoading({
      isLoading: false
    });
    set_Validated(false);
  }

  async function updateCustomerOtherInfo() {
    setLoading({
      isLoading: true,
      message: "A atualizar o cliente ..."
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/customer/update",
        "POST",
        {
          Customer: {
            Id: form_Data.Customers_Id,
            PaymentMethodId: form_Data.Customers_PaymentMethodId?form_Data.Customers_PaymentMethodId:'NULL',
            CustomersComesFromId: form_Data.Customers_CustomerComesFromId?form_Data.Customers_CustomerComesFromId:'NULL',
            CustomerManagerId: form_Data.Customers_CustomerManagerId?form_Data.Customers_CustomerManagerId:'NULL',
            RouteId: form_Data.Customers_RouteId?form_Data.Customers_RouteId:'NULL',
            DoorCode: form_Data.Customers_DoorCode,
            ExternalManagement: form_Data.Customers_ExternalManagement
          }
        }
      );
      const res = await fetchData;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
    } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
          type: "error",
        });
    }
    setLoading({
      isLoading: false
    });
  }

  const location = useLocation();

  useEffect(() => {
    getCustomersDetail(location.state.id);
  }, [location]);

  /* Form validation/submit */
  const [validated, set_Validated] = useState(false);

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      updateCustomer();
    }
  };

  const handleOnChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...form_Data,
      [id]: value,
    });
  };

  const handleSwitchChange = (event) => {
    const { id, checked } = event.target;
    setFormData({
      ...form_Data,
      [id]: checked?'1':'0',
    });
  };
  /**/

  return (
    <>
      {DocumentTitle("Detalhes Cliente")}
      <Header />
      <SideNav activeItem={2} />
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <Loading open={loading.isLoading} message={loading.message} />
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Referência {form_Data.Customers_Reference}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/main">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/customer/list">Clientes</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Detalhes do Cliente
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="card card-secondary">
              <div className="card-header">
                <h3 className="card-title">Informação do Cliente</h3>
              </div>

              <Form
                noValidate
                method="post"
                onSubmit={submitFn}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <label htmlFor="Customers_Name">
                        Nome (contato)
                      </label>
                      <Form.Control
                        type="text"
                        name="Name"
                        autocomplete="off"
                        className="form-control"
                        id="Customers_Name"
                        value={form_Data.Customers_Name}
                        onChange={handleOnChange}
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="Customers_TaxNumber">Nº Contribuinte</label>
                      <Form.Control
                        type="text"
                        name="TaxNumber"
                        className="form-control"
                        id="Customers_TaxNumber"
                        value={form_Data.Customers_TaxNumber}
                        onChange={handleOnChange}
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="CustomersStatusSelect" className="required">
                        Estado
                      </label>
                      <Form.Select
                            type="select"
                            as="select"
                            name="CustomersStatusSelect"
                            onChange={handleOnChange}
                            value={form_Data.Customers_CustomerStatusId}
                            id="Customers_CustomerStatusId"
                            required={true}
                            isInvalid={
                              !form_Data.Customers_CustomerStatusId && validated
                            }
                          >
                            <option value="">Escolher Estado</option>
                            {statusList.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            O Estado é obrigatório!
                          </Form.Control.Feedback>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-primary float-right"
                  >
                    Gravar
                  </button>
                </div>
              </Form>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card card-secondary card-outline card-outline-tabs">
                  <div className="card-header p-0 border-bottom-0">
                    <ul
                      className="nav nav-tabs"
                      id="custom-tabs-four-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="custom-tabs-four-home-tab"
                          data-toggle="pill"
                          href="#tab_1"
                          role="tab"
                          aria-controls="tab_1"
                          aria-selected="true"
                        >
                          Detalhe do Cliente
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-four-profile-tab"
                          data-toggle="pill"
                          href="#tab_2"
                          role="tab"
                          aria-controls="tab_2"
                          aria-selected="false"
                        >
                          Informação Contratual
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-four-messages-tab"
                          data-toggle="pill"
                          href="#tab_3"
                          role="tab"
                          aria-controls="tab_3"
                          aria-selected="false"
                        >
                          Elevadores
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-four-settings-tab"
                          data-toggle="pill"
                          href="#tab_4"
                          role="tab"
                          aria-controls="tab_4"
                          aria-selected="false"
                        >
                          Manutenções
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-tabs-four-settings-tab"
                          data-toggle="pill"
                          href="#tab_5"
                          role="tab"
                          aria-controls="tab_5"
                          aria-selected="false"
                        >
                          Outras Informações
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body">
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab_1">
                        <div className="card card-secondary mt-3">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Contactos</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalContactCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <ContactCreate
                              customerId={location.state.id}
                              show={modalContactCreate}
                              onHide={() => setModalContactCreate(false)}
                              onSave={() => {
                                setRefreshComponents({ contactList: new Date() })
                                setModalContactCreate(false)
                                }
                              }
                            />
                            <ContactList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              refreshControl={refreshComponents.contactList}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Endereços</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalAddressCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <AddressCreate
                              customerId={location.state.id}
                              show={modalAddressCreate}
                              onHide={() => setModalAddressCreate(false)}
                              onSave={() => {
                                setRefreshComponents({ addressList: new Date() })
                                setModalAddressCreate(false)
                                }
                              }
                            />
                            <AddressList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              refreshControl={refreshComponents.addressList}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Notas</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalNoteCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <NoteCreate
                              customerId={location.state.id}
                              show={modalNoteCreate}
                              onHide={() => setModalNoteCreate(false)}
                              onSave={() => {
                                setRefreshComponents({ noteList: new Date() })
                                setModalNoteCreate(false)
                                }
                              }
                            />
                            <NoteList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              refreshControl={refreshComponents.noteList}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_2">
                        <div className="card card-secondary mt-3">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Propostas</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalProposalCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <ProposalCreate
                              data={{
                                CustomerId: location.state.id,
                                CustomerName: form_Data.Customers_Name,
                                CustomerReference: form_Data.Customers_Reference
                                }}
                              show={modalProposalCreate}
                              onHide={() => setModalProposalCreate(false)}
                              onSave={(proposalId) => {
                                setRefreshComponents({ proposalList: new Date() })
                                setModalProposalCreate(false)
                                setModalProposalDetail({show: true, id: proposalId})
                                }
                              }
                            /> 
                            <ProposalDetail
                              id={modalProposalDetail.id}
                              show={modalProposalDetail.show}
                              onHide={() => setModalProposalDetail({show: false, id: 0})}
                              onSave={() => {
                                setRefreshComponents({ proposalList: new Date() })
                                setModalProposalDetail({show: false, id: 0})
                                }
                              }
                            />
                            <ProposalList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              columnVisibility={{
                                CustomersAddress_Street: false,
                                Customers_Name: false,
                              }}
                              refreshControl={refreshComponents.proposalList}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title mt-2">Contratos</h3>
                            <div className="card-tools">
                              <button className="btn btn-primary btn-sm" onClick={() => setModalContractCreate(true)} >
                                <i className="fas fa-plus pr-2"></i>
                                  Adicionar
                              </button>
                            </div>
                          </div>
                          <div className="card-body">
                            <ContractCreate
                              data={{
                                CustomerId: location.state.id,
                                CustomerName: form_Data.Customers_Name,
                                CustomerReference: form_Data.Customers_Reference
                                }}
                              show={modalContractCreate}
                              onHide={() => setModalContractCreate(false)}
                              onSave={(contractId) => {
                                setRefreshComponents({ contractList: new Date() })
                                setModalContractCreate(false)
                                setModalContractDetail({show: true, id: contractId})
                                }
                              }
                            /> 
                            <ContractDetail
                              id={modalContractDetail.id}
                              show={modalContractDetail.show}
                              onHide={() => setModalContractDetail({show: false, id: 0})}
                              onSave={() => {
                                setRefreshComponents({ contractList: new Date() })
                                setModalContractDetail({show: false, id: 0})
                                }
                              }
                            />
                            <ContractList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              columnVisibility={{
                                CustomersAddress_Street: false,
                                Customers_Name: false,
                              }}
                              refreshControl={refreshComponents.contractList}
                            />
                          </div>
                        </div>
                        <div className="card card-secondary mt-5">
                          <div className="card-header">
                            <h3 className="card-title">Orçamentos</h3>
                          </div>
                          <div className="card-body">
                            <BudgetProposalList
                              customerId={location.state.id}
                              blankStateOnlyText={true}
                              columnVisibility={{
                                CustomersAddress_Street: false,
                                Customers_Name: false,
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_3">
                        <div className="mb-3 text-right">
                          <button className="btn btn-primary btn-sm" onClick={() => setModalElevatorCreate(true)} >
                            <i className="fas fa-plus pr-2"></i>
                              Adicionar
                          </button>
                        </div>
                        <div className="mt-3">
                          <ElevatorCreate
                              data={{
                                CustomerId: location.state.id,
                                CustomerName: form_Data.Customers_Name,
                                CustomerReference: form_Data.Customers_Reference
                                }}
                              show={modalElevatorCreate}
                              onHide={() => setModalElevatorCreate(false)}
                              onSave={() => {
                                setRefreshComponents({ elevatorList: new Date() });
                                setModalElevatorCreate(false);
                                }
                              }
                          /> 
                          <ElevatorList
                            customerId={location.state.id}
                            blankStateOnlyText={true}
                            columnVisibility={{
                              CustomersAddress_Street: false,
                              Customers_Name: false,
                            }}
                            refreshControl={refreshComponents.elevatorList}
                          />
                        </div>
                      </div>

                      <div className="tab-pane" id="tab_4">
                        Em desenvolvimento ...
                      </div>

                      <div className="tab-pane" id="tab_5">
                        <div className="row">
                          <div className="col-4">
                            <label htmlFor="PaymentMethodsSelect">
                              Método de Pagamento
                            </label>
                            <Form.Select
                            type="select"
                            as="select"
                            name="PaymentMethodsSelect"
                            onChange={handleOnChange}
                            value={form_Data.Customers_PaymentMethodId}
                            id="Customers_PaymentMethodId"
                          >
                            <option value="">Escolher Método de Pagamento</option>
                            {paymentMethodList.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </Form.Select>
                          </div>
                          <div className="col-4">
                            <label htmlFor="CustomerManagers">
                              Gestora
                            </label>
                            <Form.Select
                              type="select"
                              as="select"
                              name="CustomerManagersSelect"
                              onChange={handleOnChange}
                              value={form_Data.Customers_CustomerManagerId}
                              id="Customers_CustomerManagerId"
                            >
                              <option value="">Escolher Gestora</option>
                              {customerManagerList.map((item, index) => (
                                <option key={index} value={item.Id}>
                                  {item.Name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="col-4">
                            <label htmlFor="RoutesSelect">
                              Rota
                            </label>
                            <Form.Select
                              type="select"
                              as="select"
                              name="RoutesSelect"
                              onChange={handleOnChange}
                              value={form_Data.Customers_RouteId}
                              id="Customers_RouteId"
                            >
                              <option value="">Escolher Rota</option>
                              {routesList.map((item, index) => (
                                <option key={index} value={item.Id}>
                                  {item.Name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-4">
                            <label htmlFor="CustomerComesFromSelect">
                              Proveniência do Cliente
                            </label>
                            <Form.Select
                              type="select"
                              as="select"
                              name="CustomerComesFromSelect"
                              onChange={handleOnChange}
                              value={form_Data.Customers_CustomerComesFromId}
                              id="Customers_CustomerComesFromId"
                            >
                              <option value="">Escolher Proveniência do Cliente</option>
                              {comesFromList.map((item, index) => (
                                <option key={index} value={item.Id}>
                                  {item.Name}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                          <div className="col-4">
                            <label htmlFor="Customers_DoorCode">
                              Código da Porta
                            </label>
                            <Form.Control
                              type="text"
                              name="DoorCode"
                              autocomplete="off"
                              className="form-control"
                              id="Customers_DoorCode"
                              value={form_Data.Customers_DoorCode}
                              onChange={handleOnChange}
                            />
                          </div>
                          <div className="col-4">
                            <label htmlFor="ExternalManagement">
                              Manutenção Externa
                            </label>
                            <Form.Check
                              type="switch"
                              name="ExternalManagement"
                              autocomplete="off"
                              id="Customers_ExternalManagement"
                              checked={form_Data.Customers_ExternalManagement==='1'}
                              onChange={handleSwitchChange}
                            />
                          </div>
                        </div>
                        <div className="row mt-4 float-right">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={updateCustomerOtherInfo}
                          >
                            Gravar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
      
      <Footer />
    </>
  );
};

export default CustomersDetails;
