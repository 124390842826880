import  secureLocalStorage  from  "react-secure-storage";

const getLocalData = (table,key) => {

    try {
        const result = JSON.parse(secureLocalStorage.getItem(table));
        return result[key];
      } catch (err) {
        return null;
      };
      
};

export default getLocalData;
