import getLocalData from "./getLocalData";

export const fetchAPI = (endpoint,method,body,header) => {

    const requiredLogData = {
      ...body,
      Log: {
        UsersId: getLocalData("user", "UserId"),
        TenantId: getLocalData("user", "TenantId"),
        IsPortal: 1,
      }
    }

    const headers = {
      ...header,
      "Authorization": "Basic " + btoa(process.env.REACT_APP_API_AUTHORIZATION)
    }

    return fetch(process.env.REACT_APP_API_DOMAIN+endpoint, {
        method: method,
        headers: headers,
        body: method==='POST'?JSON.stringify(requiredLogData):null
    })
      .then((res) => res.json()).then((responseData) => {
        return responseData;
      })
  
  }