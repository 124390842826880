import { React, useState, useEffect } from "react";
import { fetchAPI } from "../../functions/fetchAPI";
import SnackbarAlert from "../SnackbarAlert";
import PopUpLoading from "../PopUpLoading";
import { Modal, Form } from "react-bootstrap";
import getLocalData from "../../functions/getLocalData";

const ElevatorCreate = (props) => {

  const [loading, setLoading] = useState({
    isLoading: false,
    message: "",
  });

  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });

  const [modalShow, setModalShow] = useState(false);

  const [customerList, setCustomer] = useState([
    {
      Customer_Id: "",
      Customer_Reference: "",
      Customer_Name: "",
    },
  ]);

  const [form_Data, setFormData] = useState({
    Customer_Reference: "",
    Customer_Name: "",
    Elevator_CustomerId: props.data.CustomerId,
    Elevator_Number: "",
    Elevator_Load: "",
    Elevator_Floors: "",
    Elevator_Speed: "",
    Elevator_StartService: "",
    Elevator_InspectionDate: "",
    Elevator_ReprovalDate: "",
    Elevator_ManufactuerId: "",
    Elevator_InstallationTypeId: "",
    Elevator_INE: "",
    Elevator_Line: "",
    Elevator_Registry: "",
    Elevator_CouncilId: ""
  });

  const [InstallationTypesList, setInstallationTypes] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [ManufactuersList, setManufactuers] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  const [CouncilList, setCouncil] = useState([
    {
      Id: "",
      Name: "",
    },
  ]);

  async function getInitData(customerId) {
    setLoading({
      isLoading: true,
      message: "A obter dados ...",
    });
    try {
      if (!customerId) {
        const fetchCustomers = await fetchAPI(
          `/api.php/customer/list?tenant=${getLocalData("tenant", "id")}`,
          "GET"
        );
        const resCustomers = await fetchCustomers;
        setCustomer(resCustomers);
      } else {
        const fetchNextElevNumber = await fetchAPI(
          `/api.php/elevator/getNextNumber?CustomerId=${customerId}`,
          "GET"
        );
        const resNextElevNumber = await fetchNextElevNumber;
        setFormData({
         ...form_Data,
          Elevator_CustomerId: customerId,
          Elevator_Number: resNextElevNumber.Elevator_NextNumber,
        });
      }

      const fetchInstallationTypes = await fetchAPI(
        `/api.php/system/installationtype/list?isEnabled=1&tenant=${getLocalData("tenant", "id")}`,
        "GET"
      );
      const fetchManufactuers = await fetchAPI(
        `/api.php/system/manufactuer/list?isEnabled=1&tenant=${getLocalData("tenant", "id")}`,
        "GET"
      );
      const fetchCouncils = await fetchAPI(
        `/api.php/system/council/list?isEnabled=1&tenant=${getLocalData("tenant", "id")}`,
        "GET"
      );
      const resInstallationTypes = await fetchInstallationTypes;
      const resManufactuers = await fetchManufactuers;
      const resCouncils = await fetchCouncils;
      setInstallationTypes(resInstallationTypes);
      setManufactuers(resManufactuers);
      setCouncil(resCouncils);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }

    setLoading({
      isLoading: false,
      message: "",
    });
  }

  async function createElevator() {
    setLoading({
      isLoading: true,
      message: "A adicionar o elevador ..."
    });
    setAlert({ open: false, type: alert.type, message: alert.message });
    try {
      const fetchData = await fetchAPI(
        "/api.php/elevator/create",
        "POST",
        {
          Elevator: {
            CustomerId: form_Data.Elevator_CustomerId,
            Load: form_Data.Elevator_Load,
            Speed: form_Data.Elevator_Speed,
            StartService: form_Data.Elevator_StartService?form_Data.Elevator_StartService:'NULL',
            ManufactuerId: form_Data.Elevator_ManufactuerId?form_Data.Elevator_ManufactuerId:'NULL',
            InstallationTypeId: form_Data.Elevator_InstallationTypeId?form_Data.Elevator_InstallationTypeId:'NULL',
            INE: form_Data.Elevator_INE,
            Registry: form_Data.Elevator_Registry,
            Floors: form_Data.Elevator_Floors?form_Data.Elevator_Floors:'NULL',
            Number: form_Data.Elevator_Number,
            CouncilId: form_Data.Elevator_CouncilId?form_Data.Elevator_CouncilId:'NULL',
            Line: form_Data.Elevator_Line?form_Data.Elevator_Line:'NULL',
            InspectionDate: form_Data.Elevator_InspectionDate?form_Data.Elevator_InspectionDate:'NULL',
            ReprovalDate: form_Data.Elevator_ReprovalDate?form_Data.Elevator_ReprovalDate:'NULL',
          },
          Customers_Id: form_Data.Elevator_CustomerId,
        }
      );
      const res = await fetchData;
        setAlert({
          open: true,
          message: res.message,
          type: res.status,
        });
        if(res.status!=='error') {
          props.onSave();
        } else {
          setLoading({
            isLoading: false
          });
        }
    } catch (err) {
        setAlert({
          open: true,
          message: "Ocorreu um erro a tentar gravar os dados. Tente novamente.",
          type: "error",
        });
        setLoading({
          isLoading: false
        });
    }
    set_Validated(false);
  }

  useEffect(() => {
    if (props.show) {
      setModalShow(true);
      getInitData(props.data.CustomerId);
    } else {
      setModalShow(false);
    }

    setFormData({
      Customer_Reference: "",
      Customer_Name: "",
      Elevator_CustomerId: props.data.CustomerId,
      Elevator_Number: "",
      Elevator_Load: "",
      Elevator_Floors: "",
      Elevator_Speed: "",
      Elevator_StartService: "",
      Elevator_InspectionDate: "",
      Elevator_ReprovalDate: "",
      Elevator_ManufactuerId: "",
      Elevator_InstallationTypeId: "",
      Elevator_INE: "",
      Elevator_Line: "",
      Elevator_Registry: "",
      Elevator_CouncilId: ""
    });

  }, [props]);

  const [validated, set_Validated] = useState(false);

  const submitFn = (event) => {
    const form = event.currentTarget;

    set_Validated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      createElevator();
    }
  };


  const handleOnChange = (event) => {
    const { id, value } = event.target;
      setFormData({
        ...form_Data,
        [id]: value,
      });
  };

  const handleCustomerOnChange = (event) => {
    const { value } = event.target;

    if(value) {
      getInitData(value);
    }

    handleOnChange(event);

  };

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => {
          props.onHide();
          setModalShow(false);
        }}
        backdrop="static"
      >
        <PopUpLoading open={loading.isLoading} />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adicionar Elevador
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid pb-4">
            <Form
              noValidate
              method="post"
              onSubmit={submitFn}
              id="elevatorForm"
            >
              <div className="row">
                <div className="col-6">
                  <label
                    htmlFor="CustomersSelect"
                    className={!props.data.CustomerId ? "required" : null}
                  >
                    Cliente
                  </label>
                  {props.data.CustomerId ? (
                    <div>
                      {props.data.CustomerReference +
                        " - " +
                        props.data.CustomerName}
                    </div>
                  ) : (
                    <>
                      <Form.Select
                        type="select"
                        as="select"
                        name="CustomersSelect"
                        onChange={handleCustomerOnChange}
                        id="Elevator_CustomerId"
                        required={true}
                        isInvalid={!form_Data.Elevator_CustomerId && validated}
                      >
                        <option value="">Escolher Cliente</option>
                        {customerList.map((item, index) => (
                          <option key={index} value={item.Customer_Id}>
                            {item.Customer_Reference +
                              " - " +
                              item.Customer_Name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        O Cliente é obrigatório!
                      </Form.Control.Feedback>
                    </>
                  )}
                </div>
                <div className="col-6"></div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <label htmlFor="ElevatorNumber" className="required">
                    Nº do Elevador
                  </label>
                  <Form.Control
                    type="number"
                    name="ElevatorNumber"
                    className="form-control"
                    id="Elevator_Number"
                    value={form_Data.Elevator_Number}
                    onChange={handleOnChange}
                    required={true}
                    isInvalid={!form_Data.Elevator_Number && validated}
                  />
                  <Form.Control.Feedback type="invalid">
                    O Número do Elevador é obrigatório!
                  </Form.Control.Feedback>
                </div>
                <div className="col-4"></div>
                <div className="col-4"></div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <label htmlFor="ElevatorFloors">Nº de Pisos</label>
                  <Form.Control
                    type="number"
                    name="ElevatorFloors"
                    className="form-control"
                    id="Elevator_Floors"
                    value={form_Data.Elevator_Floors}
                    onChange={handleOnChange}
                    required={false}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="ElevatorLoad" className="required">
                    Carga
                  </label>
                  <Form.Control
                    type="number"
                    name="ElevatorLoad"
                    className="form-control"
                    id="Elevator_Load"
                    value={form_Data.Elevator_Load}
                    onChange={handleOnChange}
                    required={true}
                    isInvalid={!form_Data.Elevator_Load && validated}
                  />
                  <Form.Control.Feedback type="invalid">
                    A Carga é obrigatória!
                  </Form.Control.Feedback>
                </div>
                <div className="col-4">
                  <label htmlFor="ElevatorSpeed" className="required">
                    Velocidade
                  </label>
                  <Form.Control
                    type="number"
                    name="ElevatorSpeed"
                    className="form-control"
                    id="Elevator_Speed"
                    value={form_Data.Elevator_Speed}
                    onChange={handleOnChange}
                    required={true}
                    isInvalid={!form_Data.Elevator_Speed && validated}
                  />
                  <Form.Control.Feedback type="invalid">
                    A Velocidade é obrigatória!
                  </Form.Control.Feedback>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <label htmlFor="ElevatorStartService">
                    Entrada ao Serviço
                  </label>
                  <Form.Control
                    type="date"
                    name="ElevatorStartService"
                    className="form-control"
                    id="Elevator_StartService"
                    value={form_Data.Elevator_StartService}
                    onChange={handleOnChange}
                    required={false}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="ElevatorInspectionDate">
                    Data da Inspecção
                  </label>
                  <Form.Control
                    type="date"
                    name="ElevatorInspectionDate"
                    className="form-control"
                    id="Elevator_InspectionDate"
                    value={form_Data.Elevator_InspectionDate}
                    onChange={handleOnChange}
                    required={false}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="ElevatorReprovalDate">
                    Data de Reprovação
                  </label>
                  <Form.Control
                    type="date"
                    name="ElevatorReprovalDate"
                    className="form-control"
                    id="Elevator_ReprovalDate"
                    value={form_Data.Elevator_ReprovalDate}
                    onChange={handleOnChange}
                    required={false}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <label htmlFor="InstallationTypesSelect">
                    Tipo de Instalação
                  </label>
                  <Form.Select
                    type="select"
                    as="select"
                    name="InstallationTypesSelect"
                    onChange={handleOnChange}
                    value={form_Data.Elevator_InstallationTypeId?form_Data.Elevator_InstallationTypeId:''}
                    id="Elevator_InstallationTypeId"
                    required={false}
                  >
                    <option value="">Escolher Tipo de Instalação</option>
                    {InstallationTypesList.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-4">
                  <label htmlFor="ManufactuersSelect">
                    Fabricante
                  </label>
                  <Form.Select
                    type="select"
                    as="select"
                    name="ManufactuersSelect"
                    onChange={handleOnChange}
                    value={form_Data.Elevator_ManufactuerId?form_Data.Elevator_ManufactuerId:''}
                    id="Elevator_ManufactuerId"
                    required={false}
                  >
                    <option value="">Escolher Fabricante</option>
                    {ManufactuersList.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-4">
                  <label htmlFor="CouncilSelect">
                    Câmara
                  </label>
                  <Form.Select
                    type="select"
                    as="select"
                    name="CouncilSelect"
                    onChange={handleOnChange}
                    value={form_Data.Elevator_CouncilId?form_Data.Elevator_CouncilId:''}
                    id="Elevator_CouncilId"
                    required={false}
                  >
                    <option value="">Escolher Câmara</option>
                    {CouncilList.map((item, index) => (
                      <option key={index} value={item.Id}>
                        {item.Name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-4">
                  <label htmlFor="ElevatorINE">
                    INE
                  </label>
                  <Form.Control
                    type="text"
                    name="ElevatorINE"
                    className="form-control"
                    id="Elevator_INE"
                    value={form_Data.Elevator_INE}
                    onChange={handleOnChange}
                    required={false}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="ElevatorLine">
                    Nº da Linha do Elevador
                  </label>
                  <Form.Control
                    type="number"
                    name="ElevatorLine"
                    className="form-control"
                    id="Elevator_Line"
                    value={form_Data.Elevator_Line}
                    onChange={handleOnChange}
                    required={false}
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="ElevatorRegistry">
                    Registo Câmara
                  </label>
                  <Form.Control
                    type="text"
                    name="ElevatorRegistry"
                    className="form-control"
                    id="Elevator_Registry"
                    value={form_Data.Elevator_Registry}
                    onChange={handleOnChange}
                    required={false}
                  />
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary mr-auto"
            type="submit"
            form="elevatorForm"
          >
            Adicionar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ElevatorCreate;
