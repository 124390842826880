import { React, useState, useEffect} from 'react';
import MuiDataGrid from '../DataGrid';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import getLocalData from "../../functions/getLocalData";
import moment from 'moment';
import Chip from '@mui/material/Chip';
import NoteDetail from "./popup_details";

const NoteList = (props) => {
  const [data, setData] = useState([]);

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState(false);
  /**/

  const [modalDetail, setModalDetail] = useState({
    show: false,
    id: 0,
  });

  async function getNoteList(customerId) {

    const CustomerId = customerId?customerId:0;

    setLoading(true);
    try {
      const fetchData = await fetchAPI(
        "/api.php/notes/customer/list?customerId="+CustomerId+"&tenantId="+getLocalData("user", "TenantId"),
        "GET"
      );
      const res = await fetchData;
      setData(res);
      setLoading(false);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    getNoteList(props.customerId);
  }, [props.customerId,props.refreshControl]);

  const columns = [
    { 
      field: "CustomersNote_Note", 
      headerName: "Nota",
      flex: 1
    },
    {
      field: "CountNotesFiles",
      headerAlign: "center",
      align: "center",
      headerName: "Anexos",  
      flex: 1,
      renderCell: (params) => (
        params.row.CountNotesFiles>0
          ? <Chip label={params.row.CountNotesFiles>1?params.row.CountNotesFiles+" anexos":"1 anexo"} color="primary" />
          : <Chip label="sem anexos" color="default" />
      ),
    },
    { 
      field: "CustomersNotes_UpdatedAt", 
      headerName: "Última atualização",
      flex: 1,
      valueFormatter: (params) => 
        moment(params).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setModalDetail({show: true, id: params.row.CustomersNotes_Id})}
          >
            <i className="fas fa-folder pr-2"></i>
            Consultar
          </button>
        </>
      ),
    },
  ];

  const rows = data;

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pageSize={props.pageSize?props.pageSize:10}
        loading={loading}
        sortAsc={false}
        sortField="CustomersNotes_UpdatedAt"
        rowId={(params) => params.CustomersNotes_Id}
        blankStateOnlyText={props.blankStateOnlyText}
        columnVisibility={{
          ...props.columnVisibility
        }}
      />
      <NoteDetail
        id={modalDetail.id}
        customerId={props.customerId}
        show={modalDetail.show}
        onHide={() => setModalDetail({ show: false, id: 0 })}
        onSave={() => getNoteList(props.customerId)}
      />
    </>
  );
}

export default NoteList