import { React, useState, useEffect} from 'react';
import MuiDataGrid from '../DataGrid';
import { fetchAPI } from '../../functions/fetchAPI';
import SnackbarAlert from '../SnackbarAlert';
import getLocalData from "../../functions/getLocalData";
import Chip from '@mui/material/Chip';
import AddressDetail from "./popup_details";

const AddressList = (props) => {
  const [data, setData] = useState([]);

  /* Alert */
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    alert: "",
  });
  /**/

  /* Loading */
  const [loading, setLoading] = useState(false);
  /**/

  const [modalDetail, setModalDetail] = useState({
    show: false,
    id: 0,
  });

  async function getAddressList(customerId) {

    const CustomerId = customerId?customerId:0;

    setLoading(true);
    try {
      const fetchData = await fetchAPI(
        "/api.php/address/list?customerId="+CustomerId+"&tenantId="+getLocalData("user", "TenantId"),
        "GET"
      );
      const res = await fetchData;
      setData(res);
      setLoading(false);
    } catch (err) {
      setAlert({
        open: true,
        message: "Ocorreu um erro a tentar obter os dados. Tente novamente.",
        type: "error",
      });
    }
  }

  useEffect(() => {
    getAddressList(props.customerId);
  }, [props.customerId,props.refreshControl]);

  const columns = [
    { 
      field: "CustomersAddress_Street", 
      headerName: "Endereço",
      flex: 1,
      renderCell: (params) => (params.row.CustomersAddress_Street + " " + params.row.CustomersAddress_NumberFloor)
    },
    {
      field: "CustomersAddress_Local",
      headerName: "Localidade",  
      flex: 1,
      renderCell: (params) => (params.row.CustomersAddress_ZipCode + " " + params.row.CustomersAddress_Local),
    },
    { field: "CustomersAddress_City", headerName: "Concelho", flex: 1 },
    {
      field: "AddressTypes_Name",
      headerAlign: "center",
      align: "center",
      headerName: "Tipo",  
      flex: 1,
      renderCell: (params) => (
        params.row.AddressTypes_Code==='MAIN'
          ? <Chip label={params.row.AddressTypes_Name} color="primary" />
          : <Chip label={params.row.AddressTypes_Name} color="default" />
      ),
    },
    {
      field: "Actions",
      headerName: "",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => setModalDetail({show: true, id: params.row.CustomersAddress_Id})}
          >
            <i className="fas fa-folder pr-2"></i>
            Consultar
          </button>
        </>
      ),
    },
  ];

  const rows = data;

  return (
    <>
      <SnackbarAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
      />
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pageSize={props.pageSize?props.pageSize:10}
        loading={loading}
        rowId={(params) => params.CustomersAddress_Id}
        blankStateOnlyText={props.blankStateOnlyText}
        columnVisibility={{
          ...props.columnVisibility
        }}
      />

        <AddressDetail
          id={modalDetail.id}
          customerId={props.customerId}
          show={modalDetail.show}
          onHide={() => setModalDetail({show: false, id: 0})}
          onSave={() => getAddressList(props.customerId)}
        />
    </>
  );
}

export default AddressList
